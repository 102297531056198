<template lang="pug">
  v-container#user-profile(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-model='dialogBranch' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          h4 Agregar sucursal
        v-card-text
          v-row(style="margin-top: 15px;")
            v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:rules="requeridos" label='Nombre de la sucursal' type="text" maxlength="100" v-model='branchProfile.branchName')
            v-col(cols='12' md="12" style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:rules="requeridos" label='Dirección de la Sucursal' v-model='branchProfile.direccion' type='text' maxlength="300")
          v-row(style="margin-top: 15px;")
            v-col(cols="12" md="6" style="padding: 0px 10px 0px 20px;")
              v-text-field(v-model='branchProfile.estab' :rules="requeridos" label="Código Establecimiento" type="text" maxlength="3" @keypress="isNumber($event)" title="Código del establecimiento de emisión de comprobantes")
            v-col(cols="12" md="6" style="padding: 0px 10px 0px 20px;")
              v-text-field(v-model='branchProfile.ptoEmi' :rules="requeridos" label="Código del Punto de Emisión" type="text" maxlength="3" @keypress="isNumber($event)" title="Punto de emisión de comprobantes")
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="proformasLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Proforma' v-model='branchProfile.secuenciales.proforma')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="notesSaleLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='branchProfile.secuenciales.notaventa')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="notesPurchaseLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Nota de Compra' v-model='branchProfile.secuenciales.notacompra')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="facturasSaleLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Factura de Venta' v-model='branchProfile.secuenciales.facventa')
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogBranch = false')
            | SALIR
          v-btn(color='primary' text='' @click='addBranchData')
            | GUARDAR
    v-dialog(v-model='editBranch' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          h4 Editar sucursal
        v-card-text
          v-row(style="margin-top: 15px;")
            v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:rules="requeridos" label='Nombre de la sucursal' type="text" maxlength="100" v-model='branchProfile.branchNameEdit')
            v-col(cols='12' md="12" style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:rules="requeridos" label='Dirección de la Sucursal' v-model='branchProfile.direccion' type='text' maxlength="300")
          v-row(style="margin-top: 15px;")
            v-col(cols="12" md="6" style="padding: 0px 10px 0px 20px;")
              v-text-field(v-model='branchProfile.estabEdit' :rules="requeridos" label="Código Establecimiento" type="text" maxlength="3" @keypress="isNumber($event)" title="Código del establecimiento de emisión de comprobantes")
            v-col(cols="12" md="6" style="padding: 0px 10px 0px 20px;")
              v-text-field(v-model='branchProfile.ptoEmiEdit' :rules="requeridos" label="Código del Punto de Emisión" type="text" maxlength="3" @keypress="isNumber($event)" title="Punto de emisión de comprobantes")
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="proformasLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Proforma' v-model='branchProfile.secuenciales.proformaEdit')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="notesSaleLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='branchProfile.secuenciales.notaventaEdit')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="notesPurchaseLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Nota de Compra' v-model='branchProfile.secuenciales.notacompraEdit')
            v-col(cols='12' md='6' style="padding: 0px 10px 0px 20px;")
              v-text-field.purple-input(:disabled="facturasSaleLst.length > 0" type="text" @keypress="isNumber($event)" min="1" :rules="requeridos" label='Sec. Factura de Venta' v-model='branchProfile.secuenciales.facventaEdit')
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='editBranch = false')
            | SALIR
          v-btn(color='primary' text='' @click='guardarEdit')
            | GUARDAR
    v-dialog(v-model='dialogConfig' width='500')
      v-card
        v-card-title.text-h5.grey.lighten-2
          h4 Configuración de la Empresa
        v-card-text
          v-row(style="margin-top: 15px;")
            v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
              v-expansion-panels
                v-expansion-panel
                  v-expansion-panel-header
                    | Local Comercial
                  v-expansion-panel-content
                    v-row
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión de factura y ticket." style="margin: 0px;" v-model="profile.print" title="Activa la impresión de facturas y tickes en impresora térmica.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión NORMAL de documentos." style="margin: 0px;" v-model="profile.printNormal" title="Activa la impresión normal de los docuentos generados.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Campos adicionales para la factura." style="margin: 0px;" v-model="profile.aditionalField" title="Valida los campos adicionales a visualizar dentro de la factura aprobada por el SRI.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Precio Mayorista y Minorista." style="margin: 0px;" v-model="profile.productPrice" title="Activa la funcionalidad para visualizar los precios de mayorista y minorista dentro de facturación.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Nota de Venta dentro de Facturación." style="margin: 0px;" v-model="profile.saleNoteBilling" title="Activa la funcionalidad para generar una nota de venta en lugar de factura facilitando el proceso de emsión de comprobante.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Manejar Inventario Negativo." style="margin: 0px;" v-model="profile.negativeInventory" title="Activa la funcionalidad para manejar inventario negativo sin bloquear una factura en el caso de no tener productos en stock.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Generar Factura automática en error." style="margin: 0px;" v-model="profile.genAutomaticInvoice" title="Activa la funcionalidad para regenerar automáticamente una factura electrónica en caso de tener error al crearla.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Factura." style="margin: 0px;" v-model="profile.downloadFacPdf" title="Descargar PDF de Factura.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Nota de Venta." style="margin: 0px;" v-model="profile.downloadNotePdf" title="Descargar PDF de Nota de Venta.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Quitar visualización de IVA Nota de Venta." style="margin: 0px;" v-model="profile.quitIvaNote" title="Quitar visualización de IVA Nota de Venta.")
                v-expansion-panel
                  v-expansion-panel-header
                    | Restaurante
                  v-expansion-panel-content
                    v-row
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Integración de Combos." style="margin: 0px;" v-model="profile.restaurant" title="Verifica el ingreso de combos con su respectivo costo para facturación.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;" v-if="profile.restaurant")
                        v-checkbox(label="Integración de productos dentro de combos." style="margin: 0px;" v-model="profile.productComboValidation" title="Integración de productos dentro de combos.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere Mesas Abiertas." style="margin: 0px;" v-model="profile.comanda" title="Activa el módulo de mesas abiertas.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión de factura y ticket." style="margin: 0px;" v-model="profile.print" title="Activa la impresión de facturas y tickes en impresora térmica.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión NORMAL de documentos." style="margin: 0px;" v-model="profile.printNormal" title="Activa la impresión normal de los docuentos generados.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Precio Mayorista y Minorista." style="margin: 0px;" v-model="profile.productPrice" title="Activa la funcionalidad para visualizar los precios de mayorista y minorista dentro de facturación.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Nota de Venta dentro de Facturación." style="margin: 0px;" v-model="profile.saleNoteBilling" title="Activa la funcionalidad para generar una nota de venta en lugar de factura facilitando el proceso de emsión de comprobante.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Manejar Inventario Negativo." style="margin: 0px;" v-model="profile.negativeInventory" title="Activa la funcionalidad para manejar inventario negativo sin bloquear una factura en el caso de no tener productos en stock.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Generar Factura automática en error." style="margin: 0px;" v-model="profile.genAutomaticInvoice" title="Activa la funcionalidad para regenerar automáticamente una factura electrónica en caso de tener error al crearla.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Factura." style="margin: 0px;" v-model="profile.downloadFacPdf" title="Descargar PDF de Factura.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Nota de Venta." style="margin: 0px;" v-model="profile.downloadNotePdf" title="Descargar PDF de Nota de Venta.")
                v-expansion-panel
                  v-expansion-panel-header
                    | Farmacias
                  v-expansion-panel-content
                    v-row
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión de factura y ticket." style="margin: 0px;" v-model="profile.print" title="Activa la impresión de facturas y tickes en impresora térmica.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Requiere impresión NORMAL de documentos." style="margin: 0px;" v-model="profile.printNormal" title="Activa la impresión normal de los docuentos generados.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Campos adicionales para la factura." style="margin: 0px;" v-model="profile.aditionalField" title="Valida los campos adicionales a visualizar dentro de la factura aprobada por el SRI.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Precio Mayorista y Minorista." style="margin: 0px;" v-model="profile.productPrice" title="Activa la funcionalidad para visualizar los precios de mayorista y minorista dentro de facturación.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Nota de Venta dentro de Facturación." style="margin: 0px;" v-model="profile.saleNoteBilling" title="Activa la funcionalidad para generar una nota de venta en lugar de factura facilitando el proceso de emsión de comprobante.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Manejar Inventario Negativo." style="margin: 0px;" v-model="profile.negativeInventory" title="Activa la funcionalidad para manejar inventario negativo sin bloquear una factura en el caso de no tener productos en stock.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Generar Factura automática en error." style="margin: 0px;" v-model="profile.genAutomaticInvoice" title="Activa la funcionalidad para regenerar automáticamente una factura electrónica en caso de tener error al crearla.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Factura." style="margin: 0px;" v-model="profile.downloadFacPdf" title="Descargar PDF de Factura.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descargar PDF de Nota de Venta." style="margin: 0px;" v-model="profile.downloadNotePdf" title="Descargar PDF de Nota de Venta.")
                      v-col(cols="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Descuento Aplicado a Minorista." style="margin: 0px;" v-model="profile.descAplicado" title="Descuento Aplicado a Minorista.")
                v-expansion-panel
                  v-expansion-panel-header
                    | Módulos Varios
                  v-expansion-panel-content
                    v-row
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Manejar dos Cuentas." style="margin: 0px;" v-model="profile.twoAccounts" title="Activa la funcionalidad para manejar dos cuentas.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Autoconsumo." style="margin: 0px;" v-model="profile.selfConsumption" title="Activa la funcionalidad para generar autoconsumos.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Usuarios y Perfiles." style="margin: 0px;" v-model="profile.usersProfiles" title="Activa la funcionalidad para usuarios y perfiles.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Imprimir Nota de Venta preimpresa." style="margin: 0px;" v-model="profile.preIrintedNotes" title="Activa la impresión de notas de venta preimpresas.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Impresion de comprobante." style="margin: 0px;" v-model="profile.doubleNotePrinting" title="Activa la impresión de comprobante.")
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Integración de sucursales." style="margin: 0px;" v-model="profile.branchOffices" title="Activa la integración de sucursales.")
                v-expansion-panel
                  v-expansion-panel-header
                    | Cartotradesa
                  v-expansion-panel-content
                    v-row
                      v-col(cols="12" md="12" style="padding: 0px 10px 0px 20px;")
                        v-checkbox(label="Habilitar Menú Principal." style="margin: 0px;" v-model="profile.cartotradesa" title="Activa las funciones principales para el perfil de Cartotradesa.")
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='red' text='' @click='dialogConfig = false')
            | SALIR
          v-btn.success-btn(text='' @click='updateProfile')
            | GUARDAR
    v-dialog(v-model='dialogPrint' fullscreen="")
      v-card
        print-modal(:print="printBan")
    v-dialog(v-model='dialog' scrollable='' max-width='400px')
      v-card
        v-card-title
          h4 Selecciona una Imagen
        v-divider
        v-card-text(style='text-align: center;')
          img(:src='imageUrl' height='150' v-if='imageUrl' alt='Seleccione una imagen')
          v-text-field(label='Seleccionar' @click='pickFile' v-model='imageName' prepend-icon='mdi-upload' accept=".png, .jpg, .jpeg")
          input(type='file' style='display: none' ref='image' accept='image/*' @change='onFilePicked')
        v-divider
        v-card-actions
          v-spacer
            v-btn(color='red' text='' @click='dialog=false') CANCELAR
            v-btn.success-btn(text='' @click='upload') ACEPTAR
    v-dialog(v-model='dialog2' scrollable='' max-width='400px')
      v-card
        v-card-title
          h4 Selecciona una Imagen
        v-divider
        v-card-text(style='text-align: center;')
          img(:src='imageUrl2' height='150' v-if='imageUrl2' alt='Seleccione una imagen')
          v-text-field(label='Seleccionar' @click='pickFile2' v-model='imageName2' prepend-icon='mdi-upload' accept=".png, .jpg, .jpeg")
          input(type='file' style='display: none' ref='image2' accept='image/*' @change='onFilePicked2')
        v-divider
        v-card-actions
          v-spacer
            v-btn(color='red' text='' @click='dialog2=false') CANCELAR
            v-btn.success-btn(text='' @click='upload2') ACEPTAR
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-row(justify='center' style="width: 100%; margin: 0px;")
      v-col(cols='12' md='12' style="padding: 0px;")
        base-material-card.px-5.py-3(icon='mdi-folder' title='Perfil de Empresa' style='min-height: 100vh;')
          v-col(cols='12' md='12' v-if="profile.twoAccounts")
            v-select(style="font-size: 26px; height: 30px; margin-bottom: 10px;" return-object v-model='selectAccount' :items="acoountsLst" label="Seleccione la cuenta" item-text='texto')
          v-form(v-if="selectAccount.code === '001'" ref='formProfile' v-model='validProfile' lazy-validation='' autocomplete="random-string")
            v-container.py-0
              v-alert(v-model="alertDataProfile" dismissible dense='' border='left' type='info')
                | Si ha utilizado otros sistemas de {{ }}
                strong FACTURACIÓN ELECTRÓNICA {{ }}
                | verifique que los {{" "}}
                strong SECUENCIALES {{ }}
                | sean correctos en función de sus comprobantes electrónicos anteriores.
              v-row
                v-col(cols="12" md="6")
                  v-row
                    h2(style="padding-bottom: 20px; padding-top: 20px;") Datos de la Cuenta 1
                  v-row
                    v-col(cols='12' md="4")
                      v-text-field.purple-input(:rules="requeridos" label='RUC' type="text" maxlength="13" @keypress="isNumber($event)" @keyup="alterNameItem('1')" v-model='profile.rucEstablecimieto')
                    v-col(cols='12' md="8")
                      v-text-field.purple-input(@keyup="profile.direccionEstablecimiento = profile.direccionEstablecimiento.toUpperCase()" :rules="requeridos" label='Dirección de la Matriz' v-model='profile.direccionEstablecimiento' type='text' maxlength="300")
                    v-col(cols='12' md='12')
                      v-text-field.purple-input(@keyup="profile.razonSocial = profile.razonSocial.toUpperCase()" :rules="requeridos" label='Razón Social' v-model='profile.razonSocial' type='text' maxlength="300")
                    v-col(cols='12' md="12")
                      v-text-field.purple-input(@keyup="profile.nombreComercial = profile.nombreComercial.toUpperCase()" label='Nombre Comercial' v-model='profile.nombreComercial' type='text' maxlength="300")
                    // v-col(cols='12' md='4')
                      v-text-field.purple-input(:rules="requeridos" label='Nombre del establecimiento' v-model='profile.nombreEstablecimiento')
                    v-col(cols='12' md='7')
                      v-text-field.purple-input(:rules='emailRules' label='Correo del establecimiento' v-model='profile.correoEstablecimiento')
                    v-col(cols='12' md='5')
                      v-text-field.purple-input(:rules="requeridos" label='Teléfono del establecimiento' type="text" @keypress="isNumber($event)" v-model='profile.telefonoEstablecimiento')
                    //v-col(cols='12' md='6')
                      v-text-field.purple-input(label='Nombre del Representante' v-model='profile.nombreRepresentante')
                    //v-col(cols='12' md='6')
                      v-text-field.purple-input(label='Apellido del Representante' v-model='profile.apellidoRepresentante')
                    //v-col(cols='12')
                      v-textarea.purple-input(label='Descripción' value='Breve descripción del establecimiento.' v-model='profile.descripcionEstablecimiento')
                    <!--v-col(cols="12" md="4")-->
                      <!--v-checkbox(label="RESTAURANTE" style="margin: 0px;" v-model="profile.restaurant")-->
                    <!--v-col(cols="12" md="4")-->
                      <!--v-checkbox(label="IMPRESIÓN" style="margin: 0px;" v-model="profile.print")-->
                    <!--v-col(cols="12" md="4")-->
                      <!--v-checkbox(label="CONTADOR/A" style="margin: 0px;" v-model="profile.counter")-->
                v-col(cols="12" md="3")
                  h2(style="padding-bottom: 20px; padding-top: 20px; text-align: center;") Secuenciales
                  v-row
                    v-col(cols="12" md="12")
                      v-text-field(v-model='profile.estab' :rules="requeridos" label="Código Establecimiento" type="text" maxlength="3" @keypress="isNumber($event)" title="Código del establecimiento de emisión de comprobantes")
                    v-col(cols="12" md="12")
                      v-text-field(v-model='profile.ptoEmi' :rules="requeridos" label="Código del Punto de Emisión" type="text" maxlength="3" @keypress="isNumber($event)" title="Punto de emisión de comprobantes")
                    v-col(cols='12' md='6')
                      v-text-field.purple-input(:disabled="proformasLst.length > 0" type="text" @keypress="isNumber($event)"  min="1" :rules="requeridos" label='Sec. Proforma' v-model='profile.secuenciales.proforma')
                    v-col(cols='12' md='6')
                      v-text-field.purple-input(:disabled="notesSaleLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='profile.secuenciales.notaventa')
                    v-col(cols='12' md='6')
                      v-text-field.purple-input(:disabled="notesPurchaseLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Compra' v-model='profile.secuenciales.notacompra')
                    v-col(cols='12' md='6')
                      v-text-field.purple-input(:disabled="facturasSaleLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Factura de Venta' v-model='profile.secuenciales.facventa')
                    //v-col(cols='12' md='6')
                      v-text-field.purple-input(type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Factura de Compra' v-model='profile.secuenciales.faccompra')
                v-col(cols="12" md="3" style="padding-top: 50px;")
                  base-material-card.v-card-profile(v-bind:avatar='profile.urlImg' title='Logo de la Empresa' style="min-height: 220px;")
                    v-card-text.text-center
                      v-btn.mr-0(color='primary' rounded='' @click='dialog = true')
                        | SUBIR LOGO
                  v-row
                    v-col.text-center(cols="12")
                      v-btn.mr-0(color='secondary' @click='dialogConfig = true')
                        | CONFIGURACIONES
                    v-col.text-center(cols='12' md="12")
                      v-btn.mr-0(color='primary' @click='updateProfile' :loading="loading" style="width: 100%;")
                        | GUARDAR CAMBIOS
                v-divider
                v-col.text-left(cols="12" md="12" v-if="profile.branchOffices" )
                  v-row
                    v-col.text-left(cols="6" md="6" v-if="profile.branchOffices" )
                      v-btn.mr-0(color='purple' @click='dialogBranch = true')
                        | AGREGAR SUCURSAL
                    v-col.text-left(cols="6" md="6" v-if="profile.branchOffices" )
                      h2(style="padding: 0px; text-align: center;") Lista de Sucursales
                    v-divider
                    v-data-table.mx-2#tablaDatosCombo(:headers="headers" v-show="!firstLoad" :items="profile.branchs" item-key="id" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Sucursales por página\'}" style="width: 100%;")
                      template(v-slot:item.quit="{ item }")
                        v-btn(text="" icon="" color="green" @click="editarItem(item.id)")
                          v-icon mdi-pencil
                        v-btn(text="" icon="" color="red" @click="eliminarItem(item.id)")
                          v-icon mdi-delete
                      v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                        | Sin resultados
                      v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                        | Sin datos
          v-form(v-if="selectAccount.code === '002'" ref='formProfile2' v-model='validProfile2' lazy-validation='' autocomplete="random-string")
            v-container.py-0
              v-row
                v-col(cols="12" md="6")
                  v-row
                    h2(style="padding-bottom: 20px; padding-top: 20px;") Datos de la Cuenta 2
                  v-row
                    v-col(cols='12' md="4")
                      v-text-field.purple-input(:rules="requeridos" label='RUC' type="text" maxlength="13" @keypress="isNumber($event)" @keyup="alterNameItem('2')" v-model='profile.rucEstablecimieto2')
                    v-col(cols='12' md="8")
                      v-text-field.purple-input(:rules="requeridos" label='Dirección de la Matriz' v-model='profile.direccionEstablecimiento2' type='text' maxlength="300")
                    v-col(cols='12' md='12')
                      v-text-field.purple-input(:rules="requeridos" label='Razón Social' v-model='profile.razonSocial2' type='text' maxlength="300")
                    v-col(cols='12' md="12")
                      v-text-field.purple-input(label='Nombre Comercial' v-model='profile.nombreComercial2' type='text' maxlength="300")
                    // v-col(cols='12' md='4')
                      v-text-field.purple-input(:rules="requeridos" label='Nombre del establecimiento' v-model='profile.nombreEstablecimiento')
                    v-col(cols='12' md='7')
                      v-text-field.purple-input(:rules='emailRules' label='Correo del establecimiento' v-model='profile.correoEstablecimiento2')
                    v-col(cols='12' md='5')
                      v-text-field.purple-input(:rules="requeridos" label='Teléfono del establecimiento' type="text" @keypress="isNumber($event)" v-model='profile.telefonoEstablecimiento2')
                    //v-col(cols='12' md='6')
                      v-text-field.purple-input(label='Nombre del Representante' v-model='profile.nombreRepresentante')
                    //v-col(cols='12' md='6')
                      v-text-field.purple-input(label='Apellido del Representante' v-model='profile.apellidoRepresentante')
                    //v-col(cols='12')
                      v-textarea.purple-input(label='Descripción' value='Breve descripción del establecimiento.' v-model='profile.descripcionEstablecimiento')
                    <!--v-col(cols="12" md="4")-->
                    <!--v-checkbox(label="RESTAURANTE" style="margin: 0px;" v-model="profile.restaurant")-->
                    <!--v-col(cols="12" md="4")-->
                    <!--v-checkbox(label="IMPRESIÓN" style="margin: 0px;" v-model="profile.print")-->
                    <!--v-col(cols="12" md="4")-->
                    <!--v-checkbox(label="CONTADOR/A" style="margin: 0px;" v-model="profile.counter")-->
                v-col(cols="12" md="3")
                  h2(style="padding-bottom: 20px; padding-top: 20px; text-align: center;") Secuenciales
                  v-row
                    v-col(cols="12" md="12")
                      v-text-field(v-model='profile.estab2' :rules="requeridos" label="Código Establecimiento" type="text" maxlength="3" @keypress="isNumber($event)" title="Código del establecimiento de emisión de comprobantes")
                    v-col(cols="12" md="12")
                      v-text-field(v-model='profile.ptoEmi2' :rules="requeridos" label="Código del Punto de Emisión" type="text" maxlength="3" @keypress="isNumber($event)" title="Punto de emisión de comprobantes")
                    <!--v-col(cols='12' md='6')-->
                      <!--v-text-field.purple-input(:disabled="proformasLst.length > 0" type="text" @keypress="isNumber($event)"  min="1" :rules="requeridos" label='Sec. Proforma' v-model='profile.secuenciales.proforma')-->
                    <!--v-col(cols='12' md='6')-->
                      <!--v-text-field.purple-input(:disabled="notesSaleLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='profile.secuenciales.notaventa')-->
                    <!--v-col(cols='12' md='6')-->
                      <!--v-text-field.purple-input(:disabled="notesPurchaseLst.length > 0" type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Compra' v-model='profile.secuenciales.notacompra')-->
                    v-col(cols='12' md='12')
                      v-text-field.purple-input(type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Factura de Venta' v-model='profile.secuenciales2.facventa')
                    v-col(cols='12' md='12')
                      v-text-field.purple-input(type="text" @keypress="isNumber($event)"   min="1" :rules="requeridos" label='Sec. Nota de Venta' v-model='profile.secuenciales2.notaventa')
                v-col(cols="12" md="3" style="padding-top: 50px;")
                  base-material-card.v-card-profile(v-bind:avatar='profile.urlImg2' title='Logo de la Empresa' style="min-height: 220px;")
                    v-card-text.text-center
                      v-btn.mr-0(color='primary' rounded='' @click='dialog2 = true')
                        | SUBIR LOGO
                  v-row
                    <!--v-col.text-center(cols="12")-->
                      <!--v-btn.mr-0(color='secondary' @click='dialogConfig = true')-->
                        <!--| CONFIGURACIONES-->
                    v-col.text-center(cols='12' md="12")
                      v-btn.mr-0(color='primary' @click='updateProfile2' :loading="loading" style="width: 100%;")
                        | GUARDAR CAMBIOS
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import 'firebase/storage'
  import * as functions from '../../../functions'

  export default {
    components: {
      PrintModal: () => import('@/views/dashboard/component/application/PrintModal'),
    },
    data: () => ({
      headers: [
        {
          text: 'Id',
          value: 'id',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Sucursal',
          value: 'name',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Cod. Establecimiento',
          value: 'estab',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Dirección',
          value: 'dir',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'quit',
          sortable: false,
        },
      ],
      ordenarPor: 'id',
      search: '',
      cargando: false,
      firstLoad: false,
      emailRules: [
        v => !!v || 'Correo requerido',
        v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
      ],
      acoountsLst: [
        {
          code: '001',
          texto: 'Ingresar datos de la Cuenta 1.',
        },
        {
          code: '002',
          texto: 'Ingresar datos de la Cuenta 2.',
        },
      ],
      selectAccount: {
        code: '001',
        texto: 'Ingresar datos de la Cuenta 1.',
      },
      dialogConfig: false,
      dialogBranch: false,
      editBranch: false,
      editIndex: null,
      printBan: false,
      dialogPrint: false,
      alertDataProfile: true,
      alertDataProfile2: true,
      alertBranch: true,
      loading: false,
      dialog: false,
      dialog2: false,
      imageUrl: '',
      imageUrl2: '',
      imageName: '',
      imageName2: '',
      validProfile: true,
      validProfile2: true,
      print: false,
      printNormal: false,
      requeridos: [
        value => !!value || 'Requerido',
      ],
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      userProfilePreview: null,
      branchProfile: {
        branchName: '',
        estab: '001',
        ptoEmi: '001',
        branchNameEdit: '',
        estabEdit: '',
        ptoEmiEdit: '',
        direccion: '',
        secuenciales: {
          proforma: '1',
          notacompra: '1',
          notaventa: '1',
          facventa: '1',
          proformaEdit: '',
          notacompraEdit: '',
          notaventaEdit: '',
          facventaEdit: '',
        },
      },
      profile: {
        puertoimpresora: '',
        nombreEstablecimiento: '',
        correoEstablecimiento: '',
        correoEstablecimiento2: '',
        telefonoEstablecimiento: '',
        telefonoEstablecimiento2: '',
        razonSocial: '',
        razonSocial2: '',
        nombreRepresentante: '',
        apellidoRepresentante: '',
        direccionEstablecimiento: '',
        direccionEstablecimiento2: '',
        rucEstablecimieto: '',
        rucEstablecimieto2: '',
        descripcionEstablecimiento: '',
        nombreComercial: '',
        nombreComercial2: '',
        secuenciales: {
          proforma: '1',
          notacompra: '1',
          notaventa: '1',
          faccompra: '1',
          facventa: '1',
        },
        secuenciales2: {
          facventa: '1',
          notaventa: '1',
        },
        estab: '',
        ptoEmi: '',
        estab2: '',
        ptoEmi2: '',
        urlImg: '',
        urlImg2: '',
        restaurant: false,
        counter: false,
        comanda: false,
        aditionalField: false,
        productPrice: false,
        saleNoteBilling: false,
        negativeInventory: false,
        genAutomaticInvoice: false,
        productComboValidation: false,
        twoAccounts: false,
        selfConsumption: false,
        usersProfiles: false,
        preIrintedNotes: false,
        doubleNotePrinting: false,
        branchOffices: false,
        cartotradesa: false,
        downloadFacPdf: false,
        downloadNotePdf: false,
        quitIvaNote: false,
        branchs: [],
        descAplicado: false,
      },
    }),
    computed: {
      facturasSaleLst () {
        return this.$store.state.factura.facturaSaleLst
      },
      notesSaleLst () {
        return this.$store.state.factura.noteSaleLst
      },
      notesPurchaseLst () {
        return this.$store.state.factura.notePurchaseLst
      },
      proformasLst () {
        return this.$store.state.proforma.proformaLst
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      lstProfileLoaded () {
        return this.$store.state.profile.lstProfiles
      },
    },
    watch: {
      profileLoaded () {
        if (!this.userProfilePreview) {
          if (this.profileLoaded) this.profile = this.profileLoaded
          if (!this.profile.branchs) this.profile.branchs = []
          if (!this.profile.urlImg || this.profile.urlImg === '') {
            this.profile.urlImg = 'https://firebasestorage.googleapis.com/v0/b/inventarix.appspot.com/o/default%2Fit_logo.png?alt=media&token=6f52b606-1cc2-43d7-baef-4fe2ccd6862c'
          }
          if (!this.profile.urlImg2 || this.profile.urlImg2 === '') {
            this.profile.urlImg2 = 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb'
          }
          if (!this.profile.estab || this.profile.estab === '') {
            this.profile.estab = '001'
          }
          if (!this.profile.ptoEmi || this.profile.ptoEmi === '') {
            this.profile.ptoEmi = '001'
          }
          if (!this.profile.secuenciales) {
            this.profile.secuenciales = {
              proforma: '1',
              notacompra: '1',
              notaventa: '1',
              faccompra: '1',
              facventa: '1',
            }
          }
          if (!this.profile.secuenciales2) {
            this.profile.secuenciales2 = {
              facventa: '1',
              notaventa: '1',
            }
          }
        } else {
          this.userProfilePreview = null
        }
        if (this.profile.rucEstablecimieto) {
          if (this.selectAccount.code !== '002') {
            this.selectAccount = {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            }
          }
          this.acoountsLst = [
            {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            },
            {
              code: '002',
              texto: 'Ingresar datos de la Cuenta 2.',
            },
          ]
          if (this.profile.rucEstablecimieto2) {
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
              },
            ]
          }
        }
      },
    },
    mounted () {
      this.alertDataProfile = true
      if (this.profileLoaded) this.profile = this.profileLoaded
      if (!this.profile.branchs) this.profile.branchs = []
      if (!this.profile.urlImg || this.profile.urlImg === '') {
        this.profile.urlImg = 'https://firebasestorage.googleapis.com/v0/b/inventarix.appspot.com/o/default%2Fit_logo.png?alt=media&token=6f52b606-1cc2-43d7-baef-4fe2ccd6862c'
      }
      if (!this.profile.urlImg2 || this.profile.urlImg2 === '') {
        this.profile.urlImg2 = 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb'
      }
      if (!this.profile.estab || this.profile.estab === '') {
        this.profile.estab = '001'
      }
      if (!this.profile.ptoEmi || this.profile.ptoEmi === '') {
        this.profile.ptoEmi = '001'
      }
      if (!this.profile.secuenciales) {
        this.profile.secuenciales = {
          proforma: '1',
          notacompra: '1',
          notaventa: '1',
          faccompra: '1',
          facventa: '1',
        }
      }
      if (!this.profile.secuenciales2) {
        this.profile.secuenciales2 = {
          facventa: '1',
          notaventa: '1',
        }
      }
      if (this.profile.rucEstablecimieto) {
        this.selectAccount = {
          code: '001',
          texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
        }
        this.acoountsLst = [
          {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          },
          {
            code: '002',
            texto: 'Ingresar datos de la Cuenta 2.',
          },
        ]
        if (this.profile.rucEstablecimieto2) {
          this.acoountsLst = [
            {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            },
            {
              code: '002',
              texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
            },
          ]
        }
      }
    },
    methods: {
      eliminarItem (id) {
        const index = this.profile.branchs.findIndex(branch => branch.id === id)
        if (index !== -1) {
          this.profile.branchs.splice(index, 1)
        }
        this.updateProfile()
        this.snackbar = {
          show: true,
          color: 'green',
          text: 'Sucursal eliminada de manera correcta.',
        }
      },
      editarItem (id) {
        const index = this.profile.branchs.findIndex(branch => branch.id === id)
        if (index !== -1) {
          this.editBranch = true
          this.editIndex = index
          this.branchProfile.branchNameEdit = this.profile.branchs[index].name
          this.branchProfile.direccion = this.profile.branchs[index].dir
          this.branchProfile.estabEdit = this.profile.branchs[index].estab
          this.branchProfile.ptoEmiEdit = this.profile.branchs[index].ptoEmi
          this.branchProfile.secuenciales.proformaEdit = this.profile.branchs[index].secuenciales.proforma
          this.branchProfile.secuenciales.notacompraEdit = this.profile.branchs[index].secuenciales.notacompra
          this.branchProfile.secuenciales.notaventaEdit = this.profile.branchs[index].secuenciales.notaventa
          this.branchProfile.secuenciales.facventaEdit = this.profile.branchs[index].secuenciales.facventa
        }
      },
      guardarEdit () {
        if (
          this.branchProfile.branchNameEdit !== '' &&
          this.branchProfile.direccion !== '' &&
          this.branchProfile.estabEdit !== '' &&
          this.branchProfile.ptoEmiEdit !== '' &&
          this.branchProfile.secuenciales.proformaEdit !== '' &&
          this.branchProfile.secuenciales.notacompraEdit !== '' &&
          this.branchProfile.secuenciales.notaventaEdit !== ''
        ) {
          if (this.branchProfile.estabEdit === this.profile.estab ||
            this.profile.branchs.some((branch, index) =>
              branch.estab === this.branchProfile.estabEdit && index !== this.editIndex,
            )) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código de establecimiento no puede estar repetido.',
            }
            return
          }
          const data = {
            id: this.editIndex,
            name: this.branchProfile.branchNameEdit,
            dir: this.branchProfile.direccion,
            estab: this.branchProfile.estabEdit,
            ptoEmi: this.branchProfile.ptoEmiEdit,
            secuenciales: {
              proforma: this.branchProfile.secuenciales.proformaEdit,
              notacompra: this.branchProfile.secuenciales.notacompraEdit,
              notaventa: this.branchProfile.secuenciales.notaventaEdit,
              facventa: this.branchProfile.secuenciales.facventaEdit,
            },
          }
          if (Array.isArray(this.profile.branchs)) {
            this.profile.branchs[this.editIndex] = data
          } else {
            this.profile.branchs = [data]
          }
          this.$forceUpdate()
          this.updateProfile()
          this.editBranch = false
          this.editIndex = null
          this.branchProfile.branchNameEdit = ''
          this.branchProfile.direccion = ''
          this.branchProfile.estabEdit = ''
          this.branchProfile.ptoEmiEdit = ''
          this.branchProfile.secuenciales.proformaEdit = ''
          this.branchProfile.secuenciales.notacompraEdit = ''
          this.branchProfile.secuenciales.notaventaEdit = ''
          this.branchProfile.secuenciales.facventaEdit = ''
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Sucursal editada de manera correcta.',
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debe llenar todos los campos para continuar.',
          }
        }
      },
      addBranchData () {
        if (
          this.branchProfile.branchName !== '' &&
          this.branchProfile.direccion !== '' &&
          this.branchProfile.estab !== '' &&
          this.branchProfile.ptoEmi !== '' &&
          this.branchProfile.secuenciales.proforma !== '' &&
          this.branchProfile.secuenciales.notacompra !== '' &&
          this.branchProfile.secuenciales.notaventa !== ''
        ) {
          if (this.branchProfile.estab === this.profile.estab ||
            this.profile.branchs.some(branch => branch.estab === this.branchProfile.estab)) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código de establecimiento no puede estar repetido.',
            }
            return
          }
          const index = this.profile.branchs ? this.profile.branchs.length : 0
          const data = {
            id: index,
            name: this.branchProfile.branchName,
            dir: this.branchProfile.direccion,
            estab: this.branchProfile.estab,
            ptoEmi: this.branchProfile.ptoEmi,
            secuenciales: {
              proforma: this.branchProfile.secuenciales.proforma,
              notacompra: this.branchProfile.secuenciales.notacompra,
              notaventa: this.branchProfile.secuenciales.notaventa,
              facventa: this.branchProfile.secuenciales.facventa,
            },
          }
          if (Array.isArray(this.profile.branchs)) {
            this.profile.branchs[index] = data
          } else {
            this.profile.branchs = [data]
          }
          this.$forceUpdate()
          this.updateProfile()
          this.dialogBranch = false
          this.branchProfile = {
            branchName: '',
            direccion: '',
            estab: '001',
            ptoEmi: '001',
            secuenciales: {
              proforma: '1',
              notacompra: '1',
              notaventa: '1',
              facventa: '1',
            },
          }
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Sucursal agregada de manera correcta.',
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debe llenar todos los campos para continuar.',
          }
        }
      },
      alterNameItem (val) {
        this.acoountsLst = [
          {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          },
          {
            code: '002',
            texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
          },
        ]
        if (val === '1') {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
        } else {
          this.selectAccount = {
            code: '002',
            texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 1.',
          }
        }
      },
      clearProfile () {
        this.$store.dispatch('profile/setDataProfile')
      },
      pickFile () {
        this.$refs.image.click()
      },
      pickFile2 () {
        this.$refs.image2.click()
      },
      onFilePicked (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          const extension = files[0].name.split('.')[1]
          if (extension.toString() !== 'png' && extension.toString() !== 'jpg' && extension.toString() !== 'jpeg') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El archivo a subir debe ser una imagen.',
            }
            this.dialog = false
            return
          }
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      onFilePicked2 (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName2 = files[0].name
          const extension = files[0].name.split('.')[1]
          if (extension.toString() !== 'png' && extension.toString() !== 'jpg' && extension.toString() !== 'jpeg') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El archivo a subir debe ser una imagen.',
            }
            this.dialog = false
            return
          }
          if (this.imageName2.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl2 = fr.result
            this.imageFile2 = files[0]
          })
        } else {
          this.imageName2 = ''
          this.imageFile2 = ''
          this.imageUrl2 = ''
        }
      },
      upload () {
        this.userProfilePreview = this.profile
        const storageRef = firebase.storage().ref()
        const mountainsRef = storageRef.child(`images/${this.$store.state.user.user.uid}`)
        mountainsRef.put(this.imageFile).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.imageUrl = downloadURL
            this.profile.urlImg = this.imageUrl
            firebase.database().ref(`users/${this.$store.state.user.user.uid}/urlImg`).set(
              this.imageUrl,
            )
            this.dialog = false
            this.snackbar = {
              show: true,
              color: 'blue',
              text: 'Para reflejar los cambios en las facturas debes presionar el botón GUARDAR CAMBIOS.',
            }
          })
        })
      },
      upload2 () {
        this.userProfilePreview = this.profile
        const storageRef = firebase.storage().ref()
        const mountainsRef = storageRef.child(`images2/${this.$store.state.user.user.uid}`)
        mountainsRef.put(this.imageFile2).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.imageUrl2 = downloadURL
            this.profile.urlImg2 = this.imageUrl2
            firebase.database().ref(`users/${this.$store.state.user.user.uid}/urlImg2`).set(
              this.imageUrl2,
            )
            this.dialog2 = false
            this.snackbar = {
              show: true,
              color: 'blue',
              text: 'Para reflejar los cambios en las facturas debes presionar el botón GUARDAR CAMBIOS.',
            }
          })
        })
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async updateProfile () {
        this.dialogConfig = false
        if (parseFloat(this.profile.secuenciales.facventa) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de factura de venta debe ser mayor que cero.',
          }
          return
        }
        if (parseFloat(this.profile.secuenciales.proforma) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de proforma debe ser mayor que cero.',
          }
          return
        }
        if (parseFloat(this.profile.secuenciales.notacompra) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de nota de compra debe ser mayor que cero.',
          }
          return
        }
        if (parseFloat(this.profile.secuenciales.notaventa) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de nota de venta debe ser mayor que cero.',
          }
          return
        }
        if (!this.profile.urlImg || this.profile.urlImg.toString() === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa.',
          }
          return
        } else if (this.profile.urlImg === 'https://firebasestorage.googleapis.com/v0/b/inventarix.appspot.com/o/default%2Fit_logo.png?alt=media&token=6f52b606-1cc2-43d7-baef-4fe2ccd6862c') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa.',
          }
          return
        }
        if (this.$refs.formProfile.validate()) {
          const lstFilter = this.lstProfileLoaded.filter(item => {
            return item.rucEstablecimieto === this.profile.rucEstablecimieto
          })
          let banNew = true
          if (lstFilter.length > 0) {
            if (this.profile.email !== lstFilter[0].email) {
              banNew = false
            }
          }
          if (!banNew) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC ya se encuentra ingresado en otra cuenta.',
            }
            return
          }
          if (this.profile.estab.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código de establecimiento debe ser de 3 dígitos.',
            }
            return
          }
          if (this.profile.ptoEmi.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código del punto de emisión debe ser de 3 dígitos.',
            }
            return
          }
          // validacion para RUC o cedula
          let result = 0
          if (this.profile.rucEstablecimieto.length > 10) { // se validac omo RUC
            result = functions.validIdentNumber(false, this.profile.rucEstablecimieto)
          } else { // se valida como cedula
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC debe ser de 13 dígitos.',
            }
            return
          }
          if (!this.$store.state.facturacion.tokenAdmin) {
            await this.$store.dispatch('facturacion/adminLoginFac')
          }
          if (result.toString() === '1') {
            this.loading = true
            let logoBase64 = ''
            this.$store.dispatch('facturacion/getComanyImg', this.profile.urlImg)
              .then(dataUrl => {
                logoBase64 = dataUrl.split(',')[1]
                const data = {
                  token: this.$store.state.facturacion.tokenAdmin,
                  body: {
                    rucPropietario: this.profile.rucEstablecimieto,
                    razonSocial: this.profile.razonSocial,
                    tipoIdentificacion: 'RUC',
                    logo: logoBase64,
                  },
                }
                this.$store.dispatch('facturacion/createCompany', data).then(res => {
                  if (res) {
                    this.$store.dispatch('profile/setProfile', this.profile).then(async resolve => {
                      this.loading = false
                      this.snackbar = {
                        show: true,
                        color: 'green',
                        text: 'Perfíl guardado correctamente.',
                      }
                    }, reject => {
                      this.loading = false
                      this.clearProfile()
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al guardar el perfil.',
                      }
                    })
                  } else {
                    this.loading = false
                    this.clearProfile()
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de realizar el proceso.',
                    }
                  }
                }, reject => {
                  this.loading = false
                  this.clearProfile()
                  if (reject.response.data.message) {
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: reject.response.data.message,
                    }
                    return
                  }
                  this.loading = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de realizar el proceso.',
                  }
                })
              })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de RUC agregado.',
            }
          }
        }
      },
      async updateProfile2 () {
        this.dialogConfig = false
        if (this.profile.rucEstablecimieto2 !== null) {
          if (this.profile.rucEstablecimieto2 !== '') {
            if (this.profile.rucEstablecimieto2 === this.profile.rucEstablecimieto) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'El RUC de la segunda cuenta no puede ser igual al RUC de la primera cuenta.',
              }
              return
            }
          }
        }
        if (parseFloat(this.profile.secuenciales2.facventa) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de factura de venta debe ser mayor que cero.',
          }
          return
        }
        if (parseFloat(this.profile.secuenciales2.notaventa) <= 0) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El secuencial de nota de venta debe ser mayor que cero.',
          }
          return
        }
        if (!this.profile.urlImg2 || this.profile.urlImg2.toString() === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa para la segunda cuenta.',
          }
          return
        } else if (this.profile.urlImg2 === 'https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/default%2Fit_logo.png?alt=media&token=401e29bd-a347-440b-b369-94e75e5dd0fb') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Para continuar debe seleccionar el logo de la empresa para la segunda cuenta.',
          }
          return
        }
        if (this.$refs.formProfile2.validate()) {
          const lstFilter = this.lstProfileLoaded.filter(item => {
            return item.rucEstablecimieto2 === this.profile.rucEstablecimieto2
          })
          let banNew = true
          if (lstFilter.length > 0) {
            if (this.profile.email !== lstFilter[0].email) {
              banNew = false
            }
          }
          if (!banNew) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC ya se encuentra ingresado en otra cuenta.',
            }
            return
          }
          if (this.profile.estab2.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código de establecimiento debe ser de 3 dígitos para la segunda cuenta.',
            }
            return
          }
          if (this.profile.ptoEmi2.length < 3) {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El código del punto de emisión debe ser de 3 dígitos para la segunda cuenta.',
            }
            return
          }
          // validacion para RUC o cedula
          let result = 0
          if (this.profile.rucEstablecimieto2.length > 10) { // se validac omo RUC
            result = functions.validIdentNumber(false, this.profile.rucEstablecimieto2)
          } else { // se valida como cedula
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El RUC debe ser de 13 dígitos para la segunda cuenta.',
            }
            return
          }
          if (!this.$store.state.facturacion.tokenAdmin) {
            await this.$store.dispatch('facturacion/adminLoginFac')
          }
          if (result.toString() === '1') {
            this.loading = true
            let logoBase64 = ''
            this.$store.dispatch('facturacion/getComanyImg', this.profile.urlImg2)
              .then(dataUrl => {
                logoBase64 = dataUrl.split(',')[1]
                const data = {
                  token: this.$store.state.facturacion.tokenAdmin,
                  body: {
                    rucPropietario: this.profile.rucEstablecimieto2,
                    razonSocial: this.profile.razonSocial2,
                    tipoIdentificacion: 'RUC',
                    logo: logoBase64,
                  },
                }
                this.$store.dispatch('facturacion/createCompany', data).then(res => {
                  if (res) {
                    this.$store.dispatch('profile/setProfile', this.profile).then(async resolve => {
                      this.loading = false
                      this.snackbar = {
                        show: true,
                        color: 'green',
                        text: 'Perfíl guardado correctamente para la segunda cuenta.',
                      }
                    }, reject => {
                      this.loading = false
                      this.clearProfile()
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al guardar el perfil de la segunda cuenta.',
                      }
                    })
                  } else {
                    this.loading = false
                    this.clearProfile()
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de realizar el proceso.',
                    }
                  }
                }, reject => {
                  this.loading = false
                  this.clearProfile()
                  if (reject.response.data.message) {
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: reject.response.data.message,
                    }
                    return
                  }
                  this.loading = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al momento de realizar el proceso.',
                  }
                })
              })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de RUC agregado para la segunda cuenta.',
            }
          }
        }
      },
    },
  }
</script>
